.item-container {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
}

.item-element {
    font-family: "Roboto", sans-serif !important;
    position: relative;
    cursor: pointer;
    aspect-ratio: 1 / 1;
    background: #000;
    flex-wrap: nowrap;
    outline: 2px solid #33333600;
    border-radius: $border-radius;
    -webkit-border-radius: $border-radius;
    -moz-border-radius: $border-radius;
    -ms-border-radius: $border-radius;
    -o-border-radius: $border-radius;
    transition: all 0.15s ease;
    -webkit-transition: all 0.15s ease;
    -moz-transition: all 0.15s ease;
    -ms-transition: all 0.15s ease;
    -o-transition: all 0.15s ease;

    .gradient {
        z-index: 1;
        width: 100%;
        height: 100%;
        padding: 10px 15px;
        position: absolute;
        border-radius: $border-radius;
        -webkit-border-radius: $border-radius;
        -moz-border-radius: $border-radius;
        -ms-border-radius: $border-radius;
        -o-border-radius: $border-radius;
}

    img {
        z-index: 0;
        align-self: center;
        margin: 0 auto;
        width: 60%;
    }

    .item-title  {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .item-title span {
        text-align: center;
    }
}

.item-element:hover {
    outline: 2px solid $primary;   
}

.active {
    outline: 2px solid $primary;   
}


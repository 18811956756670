.selected-item-container { 
    position: relative;
    background: #2a2a2e;
    margin: 20px 0;
    padding: 15px 10px;
    border-radius: $border-radius;
    -webkit-border-radius: $border-radius;
    -moz-border-radius: $border-radius;
    -ms-border-radius: $border-radius;
    -o-border-radius: $border-radius;

    span:nth-child(1) {
        margin-left: 0 !important;
    }
}


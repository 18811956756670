.rare {
    background: linear-gradient(to bottom,rgba(116,151,235, .15) 0,rgba(116,151,235,.3) 80%);
}

.common {
    
}

.uncommon {
    background: linear-gradient(to bottom,rgba(125,217,253,.15) 0,rgba(125,217,253,.3) 80%);
}

.black-market {
    background: linear-gradient(to bottom,rgba(255,0,255,.15) 0,rgba(255,0,255,.3) 80%);
}

.exotic {
    background: linear-gradient(to bottom,rgba(236,219,108,.15) 0,rgba(236,219,108,.3) 80%);
}

.import {
    background: linear-gradient(to bottom,rgba(227,90,82,.15) 0,rgba(227,90,82,.3) 80%);
}

.very-rare {
    background: linear-gradient(to bottom,rgba(158,124,252,.15) 0,rgba(158,124,252,.3) 109%);
}

.limited {
    background: linear-gradient(to bottom,rgba(247,121,57,.15) 0,rgba(247,121,57,.3) 80%);;
}
.rlg-item__paint {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-top: 1px;
    border-width: 1px;
    border-style: solid;
}

$dark-text-color: #555555;

.--default {

}

.--black {
    background: linear-gradient(135deg,#5e5e5e,#000 80%);
    border-color: #777;
}

.--titanium-white {
    background: linear-gradient(135deg,#fff,#e5e5e5 80%);
    border-color: #fff;
    color: $dark-text-color;
}

.--grey {
    background: linear-gradient(135deg,#c4c4c4,#5d5d5d 80%);
    border-color: #ddd;
}

.--crimson {
    background: linear-gradient(135deg,#ff4d4d,#b00 80%);
    border-color: #f66;
}

.--pink {
    background: linear-gradient(135deg,#ff8dce,#e52667 80%);
    border-color: #ffa6e7;
}

.--cobalt {
    background: linear-gradient(135deg,#8c9eff,#25379b 80%);
    border-color: #a5b7ff;
}

.--sky-blue {
    background: linear-gradient(135deg,#50f6ff,#008fda 80%);
    border-color: #69ffff;
    color: $dark-text-color;
}

.--burnt-sienna {
    background: linear-gradient(135deg,#995e4d,#320000 80%);
    border-color: #b27766;
}

.--saffron {
    background: linear-gradient(135deg,#ff8,#e5d121 80%);
    border-color: #ffffa1;
    color: $dark-text-color;
}


.--lime {
    background: linear-gradient(135deg,#ccff4d,#65e500 80%);
    border-color: #e5ff66;
    color: $dark-text-color;
}

.--forest-green {
    background: linear-gradient(135deg,#99fc9d,#329536 80%);
    border-color: #b2ffb6;
    color: $dark-text-color;
}

.--orange {
    background: linear-gradient(135deg,#ffff4d,#da9a00 80%);
    border-color: #ff6;
    color: $dark-text-color;
}

.--purple {
    background: linear-gradient(135deg,#e974fd,#820d96 80%);
    border-color: #ff8dff;
}

.--gold {
    background: linear-gradient(135deg,#EAF0A3,#9BA25F 80%);
    border-color: #e1e5ac;
}

.rlg-item__paint:not(.--inline) {
    position: absolute;
    color: transparent;
    top: 7px;
    width: 17px;
    height: 17px;
    right: 7px;
    background: 0 0;
    border-radius: 9px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 99;
    line-height: 1;
}